import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Video_banner from "../images/cb_goods/video.mp4"
import Slider from "react-slick"
import Step1 from "../images/cb_goods/sec1.jpg"
import Step2 from "../images/cb_goods/sec2.jpg"
import Step3 from "../images/cb_goods/sec3.jpg"
import Health from "../images/cb_goods/health.svg"
import Bcorps from "../images/cb_goods/bcorps.svg"
import Farms from "../images/cb_goods/farm.svg"
import Zero_waste from "../images/cb_goods/zero_waste.svg"
import Vegan from "../images/cb_goods/vegan.svg"
import Energy from "../images/cb_goods/clean_energy.svg"
import Ethical from "../images/cb_goods/ethical.svg"
import Startup from "../images/cb_goods/startup.svg"
import Why_cb from "../images/cb_goods/why_cb.png"
import Why_cb1 from "../images/cb_goods/mobi.png"
import Dots from "../images/cb_goods/dots.png"
import CB_logo from "../images/cb_goods/logo_cb.png"
import Feather_icon from "../images/cb_goods/feather-external-link.png"
import Badabon_logo from "../images/cb_goods/badabon_logo.png"
import Atg_logo from "../images/cb_goods/allthatgrows_logo.png"
import Ansel_logo from "../images/cb_goods/ansel.png"
import Dao_logo from "../images/cb_goods/dao_logos.jpg"
import Learningrx from "../images/cb_goods/learning.png"


import Scroll_top_img from "../images/scroll-arrow.png"

import { TweenMax } from "gsap"
import { Tween } from "react-gsap"
import { Controller, Scene } from "react-scrollmagic"


import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'



export default class AsNavFor extends Component {


    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,

        };
    }

    componentDidMount() {

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const { nav1 } = this.state;
        const { nav2 } = this.state;

        const nav = {
            asNavFor: nav1,
            ref: slider => {
                this.slider2 = slider;
            },
            slidesToShow: 5,
            slidesToScroll: 1,
            dots: false,
            centerMode: false,
            infinite: true,
            pauseOnHover: true,
            pauseOnHover: true,
            accessibility: false,
            focusOnSelect: true,

            autoplay: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,

                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };

        const view = {
            asNavFor: nav2,
            ref: slider => {
                this.slider1 = slider;
            },
            slidesToShow: 1,
            slidesToScroll: 5,
            arrows: false,
            fade: true,
            draggable: false,
            infinite: true,
            dots: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };

        return (
            <Layout>
                <div className=" cb_good_section ">

                    <div className="positive-impact" id="entity">
                        <div className="container">
                            <Controller>
                                <Scene duration={50} offset={-10} triggerElement=".positive-impact">
                                    <Tween
                                        staggerFrom={{ top: 100, opacity: 0 }}
                                        staggerTo={{
                                            opacity: 1,
                                            // ease: 'Expo.easeOut',
                                            ease: 'Linear.easeOut',
                                            delay: 0,
                                            top: 0
                                        }}
                                    >
                                        <h3>
                                            CueBlocks is driven by the ethos of 'service' & making a positive
                                            impact in this world.
                            </h3>
                                    </Tween>
                                </Scene>
                            </Controller>
                            <h4>
                                As part of the CueBlocks' 'For Good' initiative, we consciously work
                                with brands, companies, eCommerce businesses, start-ups as well as
                                purpose-driven organizations that care about making more than just
                                mere profits.
                            </h4>
                        </div>
                    </div>


                    <div className="cb_category">
                        <div className="container">
                            <h2 className="heading-cb">
                                The entities we serve as part of CueBlocks For Good, fall under three
                                broad categories:
                            </h2>
                            <div className="cat-sec sec1">
                                <span className="list-cat">1</span>
                                <div className="wd-50 ">
                                  
                                    <ScrollAnimation offset="150" animateIn="slideInLeft" animateOut initiallyVisible="true">
                                                <img src={Step1}
                                                    alt="CueBlocks for eCommerces offering products better for the Planet's Health"
                                                    className="image_responsive" />
                                        </ScrollAnimation>
                                </div>
                                <div className="wd-50 pd_lf-rgt-50">
                                    <p>
                                        eCommerce Companies (<i>established or startups</i>) that create or
                                        sell products which are better for the health of our planet and
                                        all of its inhabitants.
                                    </p>
                                </div>
                            </div>
                            <div className="cat-sec cat-right">
                                <span className="list-cat">2</span>
                                <div className="wd-50 right-pull">
                                    <img src={Step2} alt="CueBlocks for Organizations that make World Better"
                                        className="image_responsive" />
                                </div>
                                <div className="wd-50 pd_lf-rgt-50">
                                    <p>
                                        Organizations that have services or initiatives that have a
                                        direct meaningful impact on making our world better.
                                    </p>
                                </div>

                            </div>
                            <div className="cat-sec sec3">
                                <span className="list-cat">3</span>
                                <div className="wd-50 ">

                                    <ScrollAnimation  animateIn="slideInLeft" opacity="0">
                                        <img src={Step3}
                                            alt="CueBlocks for eCommerces offering products better for the Planet's Health"
                                            className="image_responsive" />
                                    </ScrollAnimation>
                                </div>
                                <div className="wd-50 pd_lf-rgt-50">
                                    <p>Purpose-driven/ Not-just-for-profit Businesses.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cb-offer-products">
                        <div className="container">
                            <h2 className="heading-cb">
                                We aim to be the wind in the sails of socially-responsible companies
                                that offer products and services in any of the following industries:
                            </h2>
                            <ul>
                                <li>
                                    <figure>
                                        <Controller>
                                            <Scene duration={100} offset={-100} triggerElement=".ft-l" reverse={true}>
                                                <Tween
                                                    staggerFrom={{ left: -100, visibility: "hidden", opacity: 0 }}
                                                    staggerTo={{
                                                        visibility: "visible",
                                                        opacity: 1,
                                                        ease: 'Linear.easeOut',
                                                        delay: 0,
                                                        left: 0
                                                    }}
                                                    stagger={1}>
                                                    <img src={Health} alt="health Wellness nutrition" className="ft-l" />
                                                </Tween>
                                            </Scene>
                                        </Controller>
                                    </figure>
                                    <p>Health, Wellness & Nutrition</p>
                                </li>
                                <li>
                                    <figure>
                                        <Controller>
                                            <Scene duration={100} offset={-100} triggerElement=".ft-up" reverse={true}>
                                                <Tween
                                                    staggerFrom={{ top: 100, visibility: "hidden", opacity: 0 }}
                                                    staggerTo={{
                                                        top: 0,
                                                        visibility: "visible",
                                                        opacity: 1,
                                                        ease: 'Linear.easeOut',
                                                        delay: 0,
                                                    }}
                                                    stagger={.3}>
                                                    <img src={Bcorps} alt="B Corps icon" className="ft-up" />
                                                </Tween>
                                            </Scene>
                                        </Controller>
                                    </figure>
                                    <p>B corps</p>
                                </li>
                                <li>
                                    <figure>
                                        <Controller>
                                            <Scene duration={100} offset={-100} triggerElement=".ft-up" reverse={true}>
                                                <Tween
                                                    staggerFrom={{ y: 0.3, right: -50, visibility: "hidden", opacity: 0 }}
                                                    staggerTo={{
                                                        y: 0,
                                                        visibility: "visible",
                                                        opacity: 1,
                                                        // ease: Linear.easeOut,
                                                        delay: 0,
                                                        right: 0
                                                    }}
                                                    stagger={.3}>
                                                    <img src={Farms} alt="Farm to Table" className="ft-up" />
                                                </Tween>
                                            </Scene>
                                        </Controller>
                                    </figure>
                                    <p>Farm to Table</p>
                                </li>
                                <li>
                                    <figure>
                                        <Controller>
                                            <Scene duration={100} offset={-100} triggerElement=".ft-r" reverse={true}>
                                                <Tween
                                                    staggerFrom={{ y: 0.3, right: -50, opacity: 0 }}
                                                    staggerTo={{
                                                        opacity: 1,
                                                        // ease: Linear.easeOut,
                                                        delay: 0,
                                                        right: 0
                                                    }}
                                                    stagger={.3}>
                                                    <img src={Zero_waste} alt="Zero Waste" className="ft-r" />
                                                </Tween>
                                            </Scene>
                                        </Controller>
                                    </figure>
                                    <p>Zero Waste</p>
                                </li>
                                <li>
                                    <figure>
                                        <img src={Vegan} alt="Vegan & Cruelty-Free" className="ft-l" />
                                    </figure>
                                    <p>Vegan & Cruelty-Free</p>
                                </li>
                                <li>
                                    <figure>
                                        <img src={Energy} alt="Clean Energy & Environment" className="ft-up" />
                                    </figure>
                                    <p>Clean Energy & Environment</p>
                                </li>
                                <li>
                                    <figure>
                                        <img src={Ethical} alt="Ethical & Sustainably sourced products/ services"
                                            className="ft-up" />
                                    </figure>
                                    <p>Ethical & Sustainably sourced products/ services</p>
                                </li>
                                <li>
                                    <figure>
                                        <Controller>
                                            <Scene offset={-100} triggerElement=".ft-r" reverse={true}>
                                                <Tween
                                                    staggerFrom={{ y: 0.3, right: -50, opacity: 0 }}
                                                    staggerTo={{
                                                        opacity: 1,
                                                        // ease: Linear.easeOut,
                                                        delay: 0,
                                                        right: 0
                                                    }}
                                                    stagger={.3}>
                                                    <img src={Startup}
                                                        alt="Startups with innovative solutions to world  problems"
                                                        className="ft-r" />
                                                </Tween>
                                            </Scene>
                                        </Controller>
                                    </figure>
                                    <p>Startups with innovative solutions to world problems</p>
                                </li>
                            </ul>
                            <div className="contactus-area">
                                <div className="left-cont">
                                    <h2 className="heading-cb">Make us part of the impact</h2>
                                    <p>Please <Link to="/contact" className="a-simple">share details</Link> of your
                                        story & project requirements</p>
                                </div>
                                <div className="right-cont">
                                    <Link to="/contact" className="contactus-btn">Contact us</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="why-cb">
                        <div className="container">
                            <div className="wd-50 why_cb_left">
                                <h2 className="heading-cb">Why CueBlocks?</h2>
                                <h6>Aligning competence with purpose</h6>
                                <p>
                                    We’ve been doing eCommerce for a while now. Since 2005 we have had
                                    extensive experience working with small and medium-sized
                                    businesses/ organizations, focusing on their eCommerce design,
                                    development, and marketing needs.
                                </p>
                                <p>
                                    Looking inwards, we feel that our association with such brands/
                                    causes will help us leave a positive impact on our planet, albeit
                                    vicariously. It’s that warm fuzzy feeling we get when we know that
                                    CueBlocks is behind these great teams which are making a positive
                                    difference in our world.
                                </p>
                                <p>
                                    We firmly believe that when Competence and Purpose align, great
                                    things can be accomplished.
                                </p>
                            </div>
                            <div className="wd-50 why_cb_right">
                                <div className="cb_right_inn">
                                    <span className="dots-frame"><img src={Dots} alt="CueBlocks" /></span>
                                    <img src={Why_cb} alt="Why choose CueBlocks" className="img-responsive hide-mobi" />
                                    <img src={Why_cb1} alt="Why choose CueBlocks" className="img-responsive show-mobi" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service_weoffer">
                        <div className="container">
                            <h2 className="heading-cb">Services we offer</h2>
                            <h6>
                                Empowering the altruists to have a greater impact by leveraging
                                digital & eCommerce
                            </h6>
                            <p>
                                As an eCommerce Agency, we offer the following areas of expertise.
                            </p>

                            <div className="services-inn-cb">
                                <div className="left-service-cb">
                                    <img src={CB_logo} alt="CueBlocks Logo" className="img-responsive" />
                                </div>
                                <div className="right-service-cb">
                                    <ul>
                                        <li>Shopify</li>
                                        <li>Magento</li>
                                        <li>Woo/WordPress</li>
                                        <li>Core PHP</li>
                                        <li>Creative Design</li>
                                        <li className="full-li">Organic Search / SEO</li>
                                        <li>Paid Advertising</li>
                                        <li>Content Services</li>
                                        <li className="full-li">Conversion Rate Optimization</li>
                                        <li>Email Marketing</li>
                                        <li>Analytics</li>
                                    </ul>
                                    <div className="contactus-area btm-cont">
                                        <div className="left-cont">
                                            <h2 className="heading-cb">Please share details of your story & project
                                                requirements</h2>
                                        </div>
                                        <div className="right-cont">
                                            <Link to="/contact" className="contactus-btn">Contact us</Link>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service_weoffer our_clients">
                        <div className="container">
                            <h2 className="heading-cb">Our Clients</h2>
                            <h6> Good Company we keep</h6>
                            <p> We proudly serve the following brands and causes:</p>


                            <div className="client show-mobi-client ">
                                <div className="client-logos">
                                    <div className="slider-nav">
                                        <Slider {...nav}>
                                            <div className="logo-outerr">
                                                <div className="log-inn">
                                                    <a href="https://mydaolabs.com/" target="_blank"
                                                        className="client-links">
                                                        <img src={Feather_icon} />
                                                    </a>
                                                    <div className="logo-client client1"></div>
                                                </div>
                                            </div>
                                            <div className="logo-outerr">
                                                <div className="log-inn">
                                                    <a href="https://badabonharvest.bio/" target="_blank"
                                                        className="client-links"> <img src={Feather_icon} /></a>
                                                    <div className="logo-client client2"></div>
                                                </div>
                                            </div>
                                            <div className="logo-outerr">
                                                <div className="log-inn">
                                                    <a href="https://www.chezatoysinc.com/" target="_blank"
                                                        className="client-links"> <img src={Feather_icon} /></a>
                                                    <div className="logo-client client3"></div>
                                                </div>
                                            </div>
                                            <div className="logo-outerr">
                                                <div className="log-inn">
                                                    <a href="http://al-anonalateenindia.org/" target="_blank"
                                                        className="client-links"> <img src={Feather_icon} /></a>
                                                    <div className="logo-client client4"></div>
                                                </div>
                                            </div>
                                            <div className="logo-outerr">
                                                <div className="log-inn">
                                                    <a href="https://www.learningrx.com" target="_blank"
                                                        className="client-links"> <img src={Feather_icon} /></a>
                                                    <div className="logo-client client6"></div>
                                                </div>
                                            </div>
                                            <div className="logo-outerr">
                                                <div className="log-inn">

                                                    <div className="logo-client client5"></div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                                <div className="client-says">
                                    <div className="slider-for">
                                        <Slider {...view}>
                                            <div className="content-client ">
                                                <h4>DAO Labs:</h4>
                                                <p>We deliver wellness through the wisdom of Chinese medicine and the
                                                    healing powers of our innovative products.</p>
                                            </div>
                                            <div className="content-client ">
                                                <h4>Badabon Harvest:</h4>
                                                <p>An initiative of the Sundarban farmers, Badabon Harvest not only
                                                    enables them to earn a sustainable and respectable livelihood but
                                                    also contributes to the
                                                    conservation of the fragile ecosystem that they inhabit and satisfy
                                                    the growing demand for
                                                    honest, chemical-free produce.</p>
                                            </div>
                                            <div className="content-client ">
                                                <h4>Cheza Toys Inc:</h4>
                                                <p>Unfolding the story of Africa through colorful fabrics &amp; finely
                                                    crafted toys.
                                                    An African-featured toy company proudly and lovingly conceived to
                                                    disrupt playtime.</p>
                                            </div>
                                            <div className="content-client ">
                                                <h4>Al-Anon Family Groups:</h4>
                                                <p>Al-Anon Family Groups is a fellowship of relatives and friends of
                                                    alcoholics who believe their lives
                                                    have been affected by someone else’s drinking. We help you overcome
                                                    the challenges posed
                                                    by a loved one with a drinking problem.</p>
                                            </div>
                                            <div className="content-client ">
                                                <h4>LearningRx:</h4>
                                                <p>LearningRx is a one-on-one brain training center that pairs clients
                                                    with brain trainers for challenging yet fun mental games that
                                                    help enhance cognitive skills.</p>
                                            </div>
                                            <div className="content-client ">
                                                <h4>Peedu's People:</h4>
                                                <p>Global initiative to offer love and kindness to our forsaken furry
                                                    friends.
                                                    Peedu's People is a group of dedicated people offering kindness and
                                                    love to the animals in
                                                    need all over the world. We fight for animal rights, hoping to be a
                                                    voice for the voiceless
                                                    and change the world ...one animal at a time.</p>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service_weoffer cb_testimonails">
                        <div className="container">
                            <h2 className="heading-cb">Client Testimonials</h2>
                            <h6>Some good words</h6>
                            <div className="testimonials-section">
                                <div className="testi-inn-block">
                                    <div className="wd-50 test-single">

                                        <div className="test-block test2">
                                            <figure>
                                                <img src={Atg_logo} alt="All That Grows Logo" />

                                            </figure>
                                            <div className="content-testi">
                                                <p>
                                                    “The website design is phenomenal, and customers
                                                    constantly rave about the packaging and design. Customers
                                                    also like the functionality, which is something that’s
                                                    helped set our business apart from competitors. Their
                                                    company is value driven, and their employees and customers
                                                    come first. I’m very happy with them. Any customer of
                                                    theirs shouldn’t have any trouble. In terms of our PPC and
                                                    comparison shopping campaigns, they’re doing exceptionally
                                                    well. We’re happy with where we’re at with them. We’re
                                                    receiving the same high quality deliverables, but at lower
                                                    rates than those offered in Silicon Valley.”
                                                </p>
                                            </div>
                                            <h6>
                                                <a href="https://www.allthatgrows.in/"
                                                    target="_blank">AllThatGrows.in</a>
                                            </h6>
                                        </div>
                                        <div className="test-block test1">
                                            <figure>
                                                <img src={Learningrx} alt="Learningrx Logo" />
                                            </figure>
                                            <div className="content-testi">
                                                <p>
                                                    “We have worked with CueBlocks and have done probably hundreds of
                                                    projects with them. We have been consistently impressed with their
                                                    professionalism, reliability, quality of work and extensive
                                                    expertise in a wide span of technologies and services. LearningRx is
                                                    a
                                                    Franchise system with more than 70 Franchised locations, and
                                                    CueBlocks has helped us meet the many demands and challenges of
                                                    building a technology
                                                    infrastructure to support multiple locations.<br /><br />
                                                    As we bring new ideas and technology demands to the table, they are
                                                    consistently able to take on new challenges with ease and expertise.
                                                    They consistently
                                                    deliver work on-time, on-budget and with the highest levels of
                                                    quality.
                                                    <br /><br />
                                                    In addition, CueBlocks is a pleasure to work with as a team—they are
                                                    friendly, easy to communicate with, highly professional and have the
                                                    highest level of
                                                    work ethics and values. I highly recommend CueBlocks!.”
                                                </p>
                                            </div>
                                            <h6>
                                                <a href="https://badabonharvest.bio/"
                                                    target="_blank">BadabonHarvest.bio</a>
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="wd-50 test-single">
                                        <div className="test-block test1">
                                            <figure>
                                                <img src={Badabon_logo} alt="Badabon Logo" />
                                            </figure>
                                            <div className="content-testi">
                                                <p>
                                                    “I found their pure professionalism and passion for doing
                                                    what they believe is best for the client to be most
                                                    impressive. They went above and beyond what we initially
                                                    thought they’d be doing. I’ve never seen this level of
                                                    rofessionalism in other developers I’ve worked with. That
                                                    was very impressive, and I’d recommend them to everyone
                                                    just for this.”
                                                </p>
                                            </div>
                                            <h6>
                                                <a href="https://badabonharvest.bio/"
                                                    target="_blank">BadabonHarvest.bio</a>
                                            </h6>
                                        </div>
                                        <div className="test-block test3">
                                            <figure>
                                                <img src={Ansel_logo} alt="Ansel and Ivy Logo" />
                                            </figure>
                                            <div className="content-testi">
                                                <p>
                                                    “Their technical competence is strong. If you present them
                                                    with a broad or specific idea around a feature that you
                                                    want to be developed, they have a keen ability to go and
                                                    find solutions to that problem. That was refreshing for us
                                                    because we didn’t have the time to go and show them how a
                                                    feature should be implemented. Their team was able to
                                                    execute solutions based on our ideas. Overall, we’re happy
                                                    with what CueBlocks did. We asked them to build a complex
                                                    e-commerce platform with a lot of rules. They delivered a
                                                    product that was not only to specification but also went
                                                    above and beyond.”
                                                </p>
                                            </div>
                                            <h6>
                                                <a href="https://anselandivy.com/" target="_blank">AnselAndIvy.com</a>
                                            </h6>
                                        </div>
                                        <div className="test-block test4">
                                            <figure>
                                                <img src={Dao_logo} alt="MyDaoLabs Logo" />
                                            </figure>
                                            <div className="content-testi">
                                                <p>
                                                    “They’re a great group to work with for precision, speed,
                                                    and affordability. They are a tremendous value for what
                                                    they provide, and they’re typically pretty fast. That’s
                                                    why we continue to work with them. Their CEO & founder was
                                                    very passionate about our company and our product, and
                                                    there was pretty strong chemistry from that perspective.
                                                    He was very complimentary of the brand and demonstrated an
                                                    incredible enthusiasm and motivation to help us achieve
                                                    our goals.”
                                                </p>
                                            </div>
                                            <h6>
                                                <a href="https://mydaolabs.com/ " target="_blank">Mydaolabs.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contactus-area">
                                <div className="left-cont">
                                    <h2 className="heading-cb">Make us part of the impact</h2>
                                    <p>Please <a href="https://www.cueblocks.com/contact.php" className="a-simple">share
                                        details</a> of your story & project requirements</p>
                                </div>
                                <div className="right-cont">
                                    <a href="https://www.cueblocks.com/contact.php" className="contactus-btn">Contact
                                        us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="scroll-to" className="case-scroll">
                        <a href="#" title="Scroll To Top">
                            <img src={Scroll_top_img} alt="Scroll To Top" />
                        </a>
                    </div>
                </div>

                <Clutch />
            </Layout>
        )

    }
}

